<div class="modal" [ngStyle]="{display: cookiePolicyModal ? 'flex' : null}">
  <div class="modal-body">
    <div class="container">
      <div class="left">
        <h5 class="title">Your privacy is important to us</h5>
        <p class="description">This website uses own cookies and third party cookies, to improve your user's experience,
          collect statistics to optimize site functionality, and deliver content tailored to your interests.
          <a href="javascript:void(0)" (click)="goToPrivacyPolicy()">Learn more</a>
        </p>
      </div>
      <div class="right">
        <button class="btn btn-block btn-primary" (click)="acceptCookie()">
          Accept <span class="icon end"><i class="flaticon-fast-forward-double-right-arrows-symbol" ></i></span>
        </button>
      </div>
    </div>
  </div>
</div>