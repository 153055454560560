import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value:string) {
    if (value !== 'None' && value !== '' && value !== null) {
      return value.split(',');
    }
  }

}