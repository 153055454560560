export const STORE_DELIVERY_TIME = 'STORE_DELIVERY_TIME';
export const RESTORE_DELIVERY_TIME = 'RESTORE_DELIVERY_TIME';
export const CLEAR_DELIVERY_TIME = 'CLEAR_DELIVERY_TIME';

export function deliveryTimeSave(state: any = {}, action) {
  switch (action.type) {
    case STORE_DELIVERY_TIME:
      return action.payload;
    case RESTORE_DELIVERY_TIME:
      return action.payload;
    case CLEAR_DELIVERY_TIME:
      return {};
    default:
      return state;
  }
}
