export const SAVE_ORDER_NOTES = 'SAVE_ORDER_NOTES';
export const RESTORE_ORDER_NOTES = 'RESTORE_ORDER_NOTES';
export const CLEAR_ORDER_NOTES = 'CLEAR_ORDER_NOTES';

export function saveOrderNotes(state: any = {}, action) {
  switch (action.type) {
    case SAVE_ORDER_NOTES:
      return action.payload;
    case RESTORE_ORDER_NOTES:
      return action.payload;
    case CLEAR_ORDER_NOTES:
      return {};
    default:
      return state;
  }
}
