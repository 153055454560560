<div class="mobile-bar" id="mobile-bar" *ngIf="hasMatchPath(path)">
  <div class="btn-group" role="group">
    <ng-container *ngFor="let item of (data | async)?.restaurant_data?.business_tel | split | slice:0:1">
      <a type="button" class="btn" href="tel:{{item.trim()}}">
        <span class="icon start"><i class="flaticon-phone-2" ></i></span> Call
      </a>
    </ng-container>
    <button type="button" class="btn" [routerLink]="['/reservation']" routerLinkActive="active" *ngIf="(data | async)?.restaurant_content?.booking_page?.is_active">
      Reserve now
    </button>
    <button type="button" class="btn" [routerLink]="['/order-online']" routerLinkActive="active" *ngIf="(data | async)?.restaurant_content?.order_online_page?.is_active">
      Order online
    </button>
  </div>
</div>
