import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promoFilter'
})
export class PromoFilterPipe implements PipeTransform {

  transform(array: any[]): any {
    if(!array) return [];
    return array.filter(item => 
      item.is_active === true &&
      new Date(item.end_date).toISOString() >= new Date().toISOString()
    );   
  }

}

