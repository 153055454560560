export const _SAVE_DELIVERY_CHARGE = '_SAVE_DELIVERY_CHARGE';
export const CLEAR = 'CLEAR';

export function _setDeliveryCharge(state: any = {}, action) {
  switch (action.type) {
    case _SAVE_DELIVERY_CHARGE:
      return action.payload;
    case CLEAR:
      return {};
    default:
      return state;
  }
}
