import {Injectable} from '@angular/core';
import { Event, NavigationStart, Router, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {
  public loading = new Subject<boolean>();

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingStart();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingClose();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  loadingStart() {
    this.loading.next(true);
  }

  loadingClose() {
    this.loading.next(false);
  }

}