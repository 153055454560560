export const PUSH_DELIVERY_ADDRESS = 'PUSH_DELIVERY_ADDRESS';
export const CLEAR = 'CLEAR';

export function reducer(state: any = {}, action) {
  switch (action.type) {
    case 'PUSH_DELIVERY_ADDRESS':
      return action.payload;
    case CLEAR:
      return {};
    default:
      return state;
  }
}
