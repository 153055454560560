export const SAVE_BOOKING_TIMES = 'SAVE_BOOKING_TIMES';
export const CLEAR_BOOKING_TIMES = 'CLEAR_BOOKING_TIMES';

export function Reducer(state: any = [], action) {

  switch (action.type) {
    case SAVE_BOOKING_TIMES:
      return action.payload;
    case CLEAR_BOOKING_TIMES:
      return {};
    default:
      return state;
  }
}
