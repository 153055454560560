import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { LoginService } from './login.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private loginService: LoginService,
        private router: Router
    ) { }

    canActivate() {
        if (this.loginService.IsloggedIn()) {
            return true;
        } else {
            this.router.navigateByUrl('sign-in');
            return false;
        } 
    }
}
