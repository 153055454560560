import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
//social login Module
import {SocialLoginModule, SocialAuthServiceConfig} from 'angularx-social-login';
import {GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
// store module
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {reducers} from './store/reducers';
//components
import {AppComponent} from './app.component';
//modules
import { CommonLayoutModule } from './components/common-layout/common-layout.module';
//services
import {HttpService} from './services/http.service';
import {SharedService} from './services/shared.service';
import {MenuService} from './services/menu.service';
import {StoreService} from './services/store.service';
import {LoginService} from './services/login.service';
import {AuthGuard} from './services/auth-guard.service';
import {SEOService} from './services/seo.service';
import {AnalyticsService} from './services/analytics.service';
import {LoadingService} from './services/loading.services';
// configure file
import {config} from 'src/app/config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), AppRoutingModule, HttpClientModule, BrowserAnimationsModule,
    StoreModule.forRoot(reducers), StoreDevtoolsModule.instrument(), SocialLoginModule,
    CommonLayoutModule
  ],
  providers: [
    HttpService, SharedService, MenuService, StoreService, LoginService, AuthGuard, SEOService, AnalyticsService, LoadingService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(config.Google_OAuth_Client_Id),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(config.Facebook_App_Id),
          }
        ],
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
