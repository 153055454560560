<!-- contact-menu start -->
<nav id="contact-menu">
  <div class="container menu">
    <span class="contacts">
      <span class="mr-1">Tel:</span>
      <li class="nav-item" *ngFor="let item of (data | async)?.restaurant_data?.business_tel | split">
        <a class="nav-link" href="tel:{{item.trim()}}">{{item.trim()}}</a>
      </li>
    </span>
    <span class="address">
      <ng-container *ngIf="(schedule | async)?.status ==='CLOSED'">
        {{'We are closed today!'}}
      </ng-container>
      <ng-container *ngIf="(schedule | async)?.status !=='CLOSED'">
        {{
          (schedule | async)?.is_collection === false && (schedule | async)?.is_delivery === false ? 'Online ordering is currently unavailable!' :
          (schedule | async)?.is_collection && (schedule | async)?.is_delivery ? 'We are open for takeaway & delivery service' :
          (schedule | async)?.is_collection && !(schedule | async)?.is_delivery ? 'We are currently open for takeaway service only' :
          !(schedule | async)?.is_collection && (schedule | async)?.is_delivery ? 'We are currently open for delivery service only' : null
        }}
      </ng-container>
    </span>
  </div>
</nav>
<!-- contact-menu end -->

<!-- top-menu start -->
<nav class="navbar navbar-expand-lg" id="top-menu">
  <div class="container menu" [ngClass]="{'koloshi-style': imgSize > 65}">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#top-menu-bar" aria-controls="top-menu-bar" aria-expanded="false" aria-label="Toggle navigation" (click)="bodyFixed()">
      <span class="icon"><i class="flaticon-left-alignment" ></i></span>
    </button>
    <a class="navbar-brand" href="javasrcipt:void(0)" [routerLink]="['/']">
      <img class="img-fluid" [defaultImage]="'./assets/images/placeholder.jpg'" [lazyLoad]="(data | async)?.restaurant_data?.logo" alt="{{(data | async)?.restaurant_data?.restaurant_name}}" #logo />
    </a>
    <div class="collapse navbar-collapse" id="top-menu-bar">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item d-block d-lg-none">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.about_page?.is_active">
          <a class="nav-link" [routerLink]="['/about-us']" routerLinkActive="active">About</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.team_page?.is_active">
          <a class="nav-link" [routerLink]="['/our-team']" routerLinkActive="active">Team</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.deals_page?.is_active">
          <a class="nav-link" [routerLink]="['/deals']" routerLinkActive="active">Deals</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.menu_page?.is_active">
          <a class="nav-link" [routerLink]="['/menu']" routerLinkActive="active">Menu</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.booking_page?.is_active">
          <a class="nav-link" [routerLink]="['/reservation']" routerLinkActive="active">Reservation</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.review_page?.is_active">
          <a class="nav-link" [routerLink]="['/reviews']" routerLinkActive="active">Reviews</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.gallery_page?.is_active">
          <a class="nav-link" [routerLink]="['/gallery']" routerLinkActive="active">Gallery</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.awards_page?.is_active">
          <a class="nav-link" [routerLink]="['/awards']" routerLinkActive="active">Awards</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.event_page?.is_active">
          <a class="nav-link" [routerLink]="['/events']" routerLinkActive="active">Events</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.blog_page?.is_active">
          <a class="nav-link" [routerLink]="['/blogs']" routerLinkActive="active">Blog</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_content?.contact_page?.is_active">
          <a class="nav-link" [routerLink]="['/contact-us']" routerLinkActive="active">Contact</a>
        </li>
        <button class="btn btn-primary" *ngIf="(user | async)?.isLogged === false" 
        [routerLink]="['/sign-in']" routerLinkActive="active">Sign In</button>
        <button class="btn btn-primary" *ngIf="(user | async)?.isLogged === true" 
        [routerLink]="['/my-account']" routerLinkActive="active">My Account</button>        
        <button class="btn btn-theme" *ngIf="(data | async)?.restaurant_content?.order_online_page?.is_active" 
        [routerLink]="['/order-online']" routerLinkActive="active">Order Online</button>
      </ul>
      <ul class="navbar-nav d-block d-lg-none info-menu">
        <li class="nav-item">
          <span class="icon start"><i class="flaticon-phone" ></i></span>
          <p class="contacts">
            <ng-container *ngFor="let item of (data | async)?.restaurant_data?.business_tel | split; index as i;">
              <a class="nav-link py-0" href="tel:{{item.trim()}}">{{item.trim()}}</a>
            </ng-container>
          </p>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_data?.email && (data | async)?.restaurant_data?.email !==''">
          <a class="nav-link"><span class="icon"><i class="flaticon-envelope" ></i></span> {{(data | async)?.restaurant_data?.email}}</a>
        </li>
        <li class="nav-item" *ngIf="(data | async)?.restaurant_data?.domain !==''">
          <a class="nav-link" href="{{(data | async)?.restaurant_data?.domain}}">
            <span class="icon"><i class="flaticon-worldwide-1" ></i></span> {{(data | async)?.restaurant_data?.domain}}
          </a>
        </li>
      </ul>
      <p class="social-btns d-block d-lg-none mt-3">
        <a *ngFor="let item of (data | async)?.restaurant_data?.social_media" href="{{item?.link}}" target="_blank" class="btn {{item.media_name}}">
          <span class="ico"><i class="flaticon-{{item.media_name}}" ></i></span>
        </a>
      </p>
      <button type="button" class="btn d-block d-lg-none close-btn" data-toggle="collapse" data-target="#top-menu-bar" aria-controls="top-menu-bar" (click)="bodyFixed()">
        <span class="icon"><i class="flaticon-unchecked" ></i></span>
      </button>
    </div>
    <div class="overlay"></div>
  </div>
</nav>
<!-- top-menu end -->