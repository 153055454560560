import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2';
//services
import { StoreService } from 'src/app/services/store.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.services';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    data: Observable<any>;
    areas: any;
    today: number = Date.now();
    userId: string;
    form: FormGroup;
    submitted: boolean = false;
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    restaurant_address: string;

    constructor(
        private store: StoreService,
        private http: HttpService,
        private loading: LoadingService,
        private fb: FormBuilder,
        @Inject(PLATFORM_ID) private readonly platformId: any,
    ) {
        this.form = this.fb.group({
            subscribed_email: ['', [Validators.required, Validators.pattern(this.emailRegex), Validators.email]]
        });
    }

    ngOnInit() {
        this.data = this.store.restaurantData;
        this.data.subscribe(d => {
            if (Object.getOwnPropertyNames(d).length !== 0) {
              let res_postcode = '';
              if (d.restaurant_data.restaurant_name) {
                res_postcode = res_postcode.concat(d.restaurant_data.restaurant_name.trim());
              }
              if (d.restaurant_data.street_address) {
                res_postcode = res_postcode.concat(', ' + d.restaurant_data.street_address.replace(/,\s*$/, '').trim());
              }
              if (d.restaurant_data.local_area) {
                res_postcode = res_postcode.concat(', ' + d.restaurant_data.local_area.replace(/,\s*$/, '').trim());
              }
              if (d.restaurant_data.city_or_town) {
                res_postcode = res_postcode.concat(', ' + d.restaurant_data.city_or_town.replace(/,\s*$/, '').trim());
              }
              if (d.restaurant_data.postcode) {
                res_postcode = res_postcode.concat(' ' + d.restaurant_data.postcode.trim());
              }
              this.restaurant_address = res_postcode.replace(/^,/, '').trim();

              //get Delivery areas
              this.areas = d.restaurant_delivery_areas;
            }
        });
        this.store.user.subscribe((response: any) => {
          if(response && response.isLogged) {
            this.userId = response['data']._id;
          }
        })
    }

    addUserSubs(data) {
        if (this.platformId === 'browser') {
          this.submitted = true;
          if (this.form.status === 'INVALID') {
            this.loading.loadingStart();
            return false;
          } else {
            if (this.userId !== undefined) {
              data.user_id = this.userId;
            }
            this.http.addUserSubscribe(data).subscribe(result => {
              if (result['status']) {
                Swal.fire('Thank you', 'Your email has been saved!!', 'success');
              }
            });
          }
        }
    }

    get f() {
        return this.form.controls;
    }

}
