import { ChangeDetectorRef, Component, OnInit, PLATFORM_ID, Inject, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';
//services
import {HttpService} from 'src/app/services/http.service';
import {StoreService} from 'src/app/services/store.service';
import {LoadingService} from 'src/app/services/loading.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  loading: boolean;
  route_path: string;

  @HostListener('window:scroll') onScroll() {
    if (this.platformId === 'browser') {
      const contactMenu = <HTMLElement>document.querySelector('#contact-menu');
      const topMenu = <HTMLElement>document.querySelector('#top-menu');
      if ((window.pageYOffset > contactMenu.clientHeight) && this.route_path !== 'order-online') {
        topMenu.classList.add('fixed-top');
      } else {
        topMenu.classList.remove('fixed-top');
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private httpService: HttpService,
    private storeService: StoreService,
    private loadingService: LoadingService
  ) {
    this.loadingService.loading.subscribe(d => {
      this.loading = d;
      this.cdr.detectChanges();
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.route_path = event.url.replace('/', '');
    });
  }

  ngOnInit() {
    this.getRestaurantData();
  }

  ngAfterViewInit() {
    this.onScroll();
  }


  getRestaurantData() {
    if (this.platformId === 'browser') {
      this.loadingService.loadingStart();
      this.httpService.getRestaurantContent().subscribe(result => {
        this.storeService.saveRestaurantData(result);
        setTimeout(() => {
          this.loadingService.loadingClose();
        }, 2000);
      }, err => {
        this.loadingService.loadingClose();
        this.router.navigate(['/network-error']);
      });
    }
  }

  onActivate() {
    if (this.platformId === 'browser') {
      window.scroll(0, 0);
      document.querySelector('body').style.removeProperty('overflow');
    }
  }

}
