export const _SAVE_PARCEL_INFO = '_SAVE_PARCEL_INFO';
export const CLEAR = 'CLEAR';

export function ParcelReducer(state: any = {}, action) {
  switch (action.type) {
    case _SAVE_PARCEL_INFO:
      return action.payload;
    case CLEAR:
      return {};
    default:
      return state;
  }
}
