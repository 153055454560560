import {Action} from '@ngrx/store';

export const SAVE_RESTAURANT_DATA = '[Data] Save';

export class RestaurantData implements Action {
  type = SAVE_RESTAURANT_DATA;

  constructor(public payload: any) {
  }
}
