import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { helpersModule } from 'src/app/_helpers/helpers.module';
//components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CheckoutBarComponent } from './checkout-bar/checkout-bar.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, CookiePolicyComponent, CheckoutBarComponent],
  exports: [HeaderComponent, FooterComponent, CookiePolicyComponent, CheckoutBarComponent],
  imports: [
    CommonModule, RouterModule,
    FormsModule, ReactiveFormsModule,
    LazyLoadImageModule,
    ModalModule.forRoot(),
    helpersModule
  ]
})
export class CommonLayoutModule { }

