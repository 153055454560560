export const BOOKING = 'BOOKING';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';

export function reducer(state: any = {}, action) {
  switch (action.type) {
    case 'BOOKING':
      return action.payload;
    case CLEAR_BOOKING:
      return {};
    default:
      return state;
  }
}
