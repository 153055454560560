export const SIGN_IN = "SIGN_IN";
export const RESTORE_USER = "RESTORE_USER";
export const UPDATE_USER = "_UPDATE_USER";
export const REMOVE_USER = 'REMOVE_USER';

export function loginReducer(state: any = {}, action) {
  switch (action.type) {
    case SIGN_IN:
      return action.payload;
    case RESTORE_USER:
      return action.payload;
    case UPDATE_USER:
      return action.payload;
    case REMOVE_USER:
      return {};
    default:
      return state;
  }
}
