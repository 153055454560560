export const ACCEPTED_PROMOTION = 'ACCEPTED_PROMOTION';
export const RESTORE_ACCEPTED_PROMOTION = 'RESTORE_ACCEPTED_PROMOTION';
export const CLEAR_ACCEPTED_PROMOTION = 'CLEAR_ACCEPTED_PROMOTION';

export function Reducer(state: any = {}, action) {
  switch (action.type) {
    case ACCEPTED_PROMOTION:
      return action.payload;
    case RESTORE_ACCEPTED_PROMOTION:
      return action.payload; 
    case CLEAR_ACCEPTED_PROMOTION:
      return {};
    default:
      return state;
  }
}
