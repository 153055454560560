import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  public regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  constructor(private sanitizer: DomSanitizer) {
    
  }
  
  public transform(url: any): any {
    // if(url) {
    //   let match = url.match(this.regExp);
    //   if(match && match[2].length === 11) {
    //     let embedUrl = '//www.youtube.com/embed/' + match[2];
    //     return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    //   }else {
    //     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //   }
    // }

    
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}
