import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
import {StoreService} from './store.service';
import {Md5} from 'ts-md5/dist/md5';

@Injectable()
export class SharedService {
  today = new Date();
  restaurantSchedule: any;
  _policy: string;

  constructor(private store: StoreService,
              @Inject(PLATFORM_ID) private readonly platformId: any) {
    // setInterval(() => {this.today = new Date()}, 1);
    if (this.platformId === 'browser') {
      this.store.restaurantData.subscribe(res => {
        if (Object.getOwnPropertyNames(res).length !== 0) {
          this.restaurantSchedule = res.restaurant_data['schedule'];
        }
      });
      if (sessionStorage.getItem('policy')) {
        this._policy = JSON.parse(sessionStorage.getItem('policy')).policy;
      }
    }
  }


  scheduleProcessing(selectedPolicy: string = this._policy) {
    const currentTime = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[currentTime.getDay()];
    if (this.restaurantSchedule && this.restaurantSchedule.length > 0) {
      const schedule = this.restaurantSchedule.filter(book => book.day_name === dayName);
      const default_schedule = schedule[0].default;
      /*
       * DOUBLE SHIFT RESTAURANT
       * */
      if (schedule[0].order_schedule.length === 2) {

        const opening_time1 = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + schedule[0].order_schedule[0].opening_time));
        const closing_time1 = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + schedule[0].order_schedule[0].closing_time));
        const opening_time2 = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + schedule[0].order_schedule[1].opening_time));
        const closing_time2 = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + schedule[0].order_schedule[1].closing_time));
        if (currentTime >= opening_time1 && currentTime < closing_time2) {


          if (currentTime >= opening_time1 && currentTime < closing_time1) {
            /**
             * RESTAURANT IS RUNNING ON FIRST SHIFT
             * OPEN
             * OPEN MODE 1ST SHIFT AND PRE-ORDER FOR 2ND SHIFT
             * **/
            const policy = schedule[0].order_schedule[0];
            let del_time = policy.delivery_time;
            del_time = selectedPolicy === 'COLLECTION' ? policy.collection_time : policy.delivery_time;

            const deliveryTime = this.changeTimeFormat(new Date(currentTime.getTime() + Number(del_time) * 60000));
            const currentTimeWithDeliveryTime = new Date(currentTime.getTime() + Number(del_time) * 60000);

            const times1 = this.generateTimeList(currentTimeWithDeliveryTime, closing_time1);
            const times2 = this.generateTimeList(new Date(opening_time2.getTime() + Number(del_time) * 60000), closing_time2);
            const times = times1.concat(times2);
            const data = {
              status: 'OPEN',
              shift: 1,
              delivery_type: ['ASAP', 'TODAY'],
              asap: deliveryTime,
              today: times,
              min_collection_amount: policy.min_collection_amount,
              min_delivery_amount: policy.min_delivery_amount,
              collection_time: policy.collection_time,
              delivery_time: policy.delivery_time,
              is_delivery: policy.is_delivery,
              is_collection: policy.is_collection,
              opening1: policy.opening_time,
              closing1: policy.closing_time,
              opening2: schedule[0].order_schedule[1].opening_time,
              closing2: schedule[0].order_schedule[1].closing_time,
              opening_hours: default_schedule[0].opening_time + ' - '
              + default_schedule[0].closing_time + ' | '
              + default_schedule[1].opening_time + ' - ' + default_schedule[1].closing_time
            };
            this.store.saveSchedule(data);// store into redux db
          }

          if (currentTime >= opening_time2 && currentTime < closing_time2) {
            /**
             * RESTAURANT OPEN IN 2ND SHIFT
             * OPEN
             * DELIVERY END BEFORE 15 MIN CLOSING.
             * **/
            const policy = schedule[0].order_schedule[1];
            let del_time = policy.delivery_time;
            del_time = selectedPolicy === 'COLLECTION' ? policy.collection_time : policy.delivery_time;

            const deliveryTime = this.changeTimeFormat(new Date(currentTime.getTime() + Number(del_time) * 60000));
            const currentTimeWithDeliveryTime = new Date(currentTime.getTime() + Number(del_time) * 60000);
            const times2 = this.generateTimeList(currentTimeWithDeliveryTime, closing_time2);

            const data = {
              status: 'OPEN',
              shift: 2,
              delivery_type: ['ASAP', 'TODAY'],
              asap: deliveryTime,
              today: times2,
              min_collection_amount: policy.min_collection_amount,
              min_delivery_amount: policy.min_delivery_amount,
              collection_time: policy.collection_time,
              delivery_time: policy.delivery_time,
              is_delivery: policy.is_delivery,
              is_collection: policy.is_collection,
              opening1: schedule[0].order_schedule[0].opening_time,
              closing1: schedule[0].order_schedule[0].closing_time,
              opening2: policy.opening_time,
              closing2: policy.closing_time,
              opening_hours: default_schedule[0].opening_time + ' - '
              + default_schedule[0].closing_time + ' | '
              + default_schedule[1].opening_time + ' - ' + default_schedule[1].closing_time
            };
            this.store.saveSchedule(data);// store into redux db
          }
          if (currentTime > closing_time1 && currentTime < opening_time2) {
            /***
             * WHEN FIRST SHIFT CLOSED AND SECOND SHIFT WILL BE START SOON.
             * PRE-ORDER MODE ON FOR 2ND SHIFT
             * **/

            const policy = schedule[0].order_schedule[1];
            let del_time = policy.delivery_time;
            del_time = selectedPolicy === 'COLLECTION' ? policy.collection_time : policy.delivery_time;

            const currentTimeWithDeliveryTime = new Date(opening_time2.getTime() + Number(del_time) * 60000);
            const data = {
              status: 'PRE-ORDER',
              shift: 2,
              delivery_type: ['TODAY'],
              today: this.generateTimeList(currentTimeWithDeliveryTime, closing_time2),
              min_collection_amount: policy.min_collection_amount,
              min_delivery_amount: policy.min_delivery_amount,
              collection_time: policy.collection_time,
              delivery_time: policy.delivery_time,
              is_delivery: policy.is_delivery,
              is_collection: policy.is_collection,
              opening1: schedule[0].order_schedule[0].opening_time,
              closing1: schedule[0].order_schedule[0].closing_time,
              opening2: policy.opening_time,
              closing2: policy.closing_time,
              opening_hours: default_schedule[0].opening_time + ' - '
              + default_schedule[0].closing_time + ' | '
              + default_schedule[1].opening_time + ' - ' + default_schedule[1].closing_time
            };
            this.store.saveSchedule(data);
          }


        } else {
          /**
           * CHECK RESTAURANT IN PRE-ORDER MODE OR CLOSED
           * PRE-ORDER FOR BOTH SHIFT
           * **/
          if (currentTime < opening_time1) {
            const policy = schedule[0].order_schedule[0];
            let del_time = policy.delivery_time;
            del_time = selectedPolicy === 'COLLECTION' ? policy.collection_time : policy.delivery_time;

            const deliveryTime = this.changeTimeFormat(new Date(opening_time1.getTime() + Number(del_time) * 60000));
            const currentTimeWithDeliveryTime = new Date(opening_time1.getTime() + Number(del_time) * 60000);

            const times1 = this.generateTimeList(currentTimeWithDeliveryTime, closing_time1);
            const times2 = this.generateTimeList(new Date(opening_time2.getTime() + Number(del_time) * 60000), closing_time2);
            const times = times1.concat(times2);
            const data = {
              status: 'PRE-ORDER',
              shift: 1,
              delivery_type: ['TODAY'],
              asap: deliveryTime,
              today: times,
              min_collection_amount: policy.min_collection_amount,
              min_delivery_amount: policy.min_delivery_amount,
              collection_time: policy.collection_time,
              delivery_time: policy.delivery_time,
              is_delivery: policy.is_delivery,
              is_collection: policy.is_collection,
              opening1: policy.opening_time,
              closing1: policy.closing_time,
              opening2: schedule[0].order_schedule[1].opening_time,
              closing2: schedule[0].order_schedule[1].closing_time,
              opening_hours: default_schedule[0].opening_time + ' - '
              + default_schedule[0].closing_time + ' | '
              + default_schedule[1].opening_time + ' - ' + default_schedule[1].closing_time
            };
            this.store.saveSchedule(data);
          }
          /**
           * RESTAURANT HAS BEEN CLOSED FOR TODAY
           * **/
          if (currentTime > closing_time2) {
            const data = {
              status: 'CLOSED',
              opening_hours: default_schedule[0].opening_time + ' - '
              + default_schedule[0].closing_time + ' | '
              + default_schedule[1].opening_time + ' - ' + default_schedule[1].closing_time
            }
            this.store.saveSchedule(data);
          }
        }
      } else if (schedule[0].order_schedule.length === 1) {
        /**
         * ----END OF DOUBLE SHIFT SCHEDULE GENERATING-----
         * ***/


        /**
         * SINGLE SHIFT RESTAURANT SCHEDULE GENERATE
         * START
         * **/
        const policy = schedule[0].order_schedule[0];
        let del_time = policy.delivery_time;
        del_time = selectedPolicy === 'COLLECTION' ? policy.collection_time : policy.delivery_time;

        const opening_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + policy.opening_time));
        const closing_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + policy.closing_time));

        if (currentTime >= opening_time && currentTime < closing_time) {
          const deliveryTime = this.changeTimeFormat(new Date(currentTime.getTime() + Number(del_time) * 60000));
          const currentTimeWithDeliveryTime = new Date(currentTime.getTime() + Number(del_time) * 60000);
          const data = {
            status: 'OPEN',
            shift: 1,
            delivery_type: ['ASAP', 'TODAY'],
            asap: deliveryTime,
            today: this.generateTimeList(currentTimeWithDeliveryTime, closing_time),
            min_collection_amount: policy.min_collection_amount,
            min_delivery_amount: policy.min_delivery_amount,
            collection_time: policy.collection_time,
            delivery_time: policy.delivery_time,
            is_delivery: policy.is_delivery,
            is_collection: policy.is_collection,
            opening1: policy.opening_time,
            closing1: policy.closing_time,
            opening_hours: default_schedule[0].opening_time + ' - ' + default_schedule[0].closing_time
          };
          this.store.saveSchedule(data);
        }

        if (currentTime < opening_time) {
          const currentTimeWithDeliveryTime = new Date(opening_time.getTime() + Number(del_time) * 60000);
          const data = {
            status: 'PRE-ORDER',
            delivery_type: ['TODAY'],
            shift: 1,
            today: this.generateTimeList(currentTimeWithDeliveryTime, closing_time),
            min_collection_amount: policy.min_collection_amount,
            min_delivery_amount: policy.min_delivery_amount,
            collection_time: policy.collection_time,
            delivery_time: policy.delivery_time,
            is_delivery: policy.is_delivery,
            is_collection: policy.is_collection,
            opening1: policy.opening_time,
            closing1: policy.closing_time,
            opening_hours: default_schedule[0].opening_time + ' - ' + default_schedule[0].closing_time
          };
          this.store.saveSchedule(data);
        }

        if (currentTime > closing_time) {
          const data = {
            status: 'CLOSED',
            opening_hours: default_schedule[0].opening_time + ' - ' + default_schedule[0].closing_time
          };
          this.store.saveSchedule(data);
        }
      } else {
        this.store.saveSchedule({status: 'CLOSED'});
      }
    }
  }

  public BookingScheduleProcessing(selectedDate) {
    if (this.getTodaysSchedule(selectedDate ? selectedDate : new Date())) {
      const policy = this.getTodaysSchedule(selectedDate ? selectedDate : new Date()).reservation_schedule;
      return new Promise((resolve) => {
        const currentTime = new Date(selectedDate);
        if (policy.length === 0) {
          resolve({status: 'CLOSED'});
        } else {
          let times = [];
          policy.forEach(item => {
            if (item.is_active) {
              const opening_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + item.opening_time));
              const closing_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + item.closing_time));
              const t = this.generateTimeList(opening_time, closing_time);
              times.push(t);
            }
          });
          resolve(times);
        }
      });
    }
  }

  private getTodaysSchedule(selected_date) {
    const _selected_date = new Date(selected_date);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[_selected_date.getDay()];
    if (this.restaurantSchedule) {
      const schedule = this.restaurantSchedule.filter(book => book.day_name === dayName);
      return schedule[0];
    }
  }

  private generateTimeList(opening, closing) {
    const deliverTimeList = [];
    for (let i = opening.getTime(); i <= closing.getTime(); i = i + (15 * 60000)) {
      const dates = new Date(i);
      const time = this.changeTimeFormat(dates);
      deliverTimeList.push(time);
    }
    return deliverTimeList;
  }

  private changeTimeFormat(date) {
    // IT CONVERTS TO 24 HOUR TIME FORMATE TO 12 HOUR TIME FORMAT
    const h = date.getHours();
    let m = date.getMinutes();
    m = ('0' + m).slice(-2);
    let _time = '';
    if (h >= 12) {
      _time = (h > 12) ? ('0' + (h - 12)).slice(-2) + ':' + m + ' ' + 'PM' : ('0' + h).slice(-2) + ':' + m + ' ' + 'PM';
    } else {
      _time = ('0' + h).slice(-2) + ':' + m + ' ' + 'AM';
    }
    return _time;
  }

  checkPromotionEligibility(totalAmount, discountsData, offersData) {
    /*  Default = 1
     Only Collection = 2
     Only Delivery = 3
     Both (Collection & Delivery) = 4
     First Order = 5
     Cash Only = 6
     Card = 7
     others = 8*/

    return new Promise(resolve => {
      const currentTime = this.today;
      let offers = [];
      let discount = [];
      if (this.platformId === 'browser') {
        let selected_policy;
        let user;
        let selected_payment;
        /*@Override@*/
        this.store.selected_policy.subscribe((result: any) => {
          if (result && result['policy']) {
            selected_policy = result['policy'] === 'COLLECTION' ? 1 : 2;
          } else {
            selected_policy = 2;
          }
        });
        this.store.user.subscribe((result: any) => {
          if(result && result['isLogged'] === true) {
            user = result['data'];
          }
        });
        this.store.paymentMethod.subscribe((result: any) => {
          if(result && result['is_active']) {
            selected_payment = result['paymentType'] === 'CARD' ? 2 : 1
          }
        });
        setTimeout(() => {
          if (offersData.length > 0) {
            /**
             * COLLECTION AND DELIVERY BOTH= 0
             * COLLECTION = 1
             * DELIVERY = 2
             * **/
            const offData = offersData.filter(item => item.its_type === 2
            && totalAmount >= item.offer_eligible_amount
            && item.is_active === true);

            if (offData.length > 0) {
              offData.forEach(item => {
                if (Number(item.offer_eligible_for) === selected_policy || Number(item.offer_eligible_for) === 0) {
                  /**
                   * - CHECK ELIGIBLE PAYMENT METHODS CASH = 1, CARD = 2, BOTH = 0
                   * - CHECK DISCOUNT START DATE AND END DATE IF NO DATE FIXED THEN IT'S FOR ALL DAY
                   * - CHECK DISCOUNT AVAILABLE TIMES
                   * - CHECK DEFAULT (AUTOMATIC ACCEPT DISCOUNT)
                   * - CHECK IS IT FIRST ORDER BY CUSTOMER
                   * **/

                  if (selected_payment) {
                    if (item.eligible_payment_methods === selected_payment || item.eligible_payment_methods === 0) {
                      /*
                        * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                        * */

                      if (item.start_date !== null && item.end_date !== null) {
                        /**
                         * - IF START AND END DATE ASSIGNED
                         */
                        const start_date = new Date(item.start_date);
                        const end_date = new Date(item.end_date);
                        if (currentTime >= start_date && currentTime <= end_date) {
                          if (item.offer_available_time.length > 0) {
                            const dayName = currentTime.getDay();
                            const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                            if (_times.length > 0) {
                              const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                              const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                              if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                                if (item.is_default) {
                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    offers.push(item);
                                  }

                                  if (item.is_first_order === false) {
                                    offers.push(item);
                                  }
                                } else {
                                  /**
                                   * - NOT DEFAULT
                                   * - USER HAVE TO ACCEPT DISCOUNT
                                   * **/

                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    offers.push(item);
                                  }

                                  if (item.is_first_order === false) {
                                    offers.push(item);
                                  }
                                }
                              }
                            } // end time compare
                          } else {
                            /**
                             * - NOT TIME GIVEN
                             * **/
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                this.store.saveAcceptedPromotion(item); // default discount added directly
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                this.store.saveAcceptedPromotion(item);
                                offers.push(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                            }
                          }
                        }
                      } else {
                        /*
                          * - IF START AND END DATE NOT GIVEN.
                          * */
                        if (item.offer_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  this.store.saveAcceptedPromotion(item); // default discount added directly
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  this.store.saveAcceptedPromotion(item);
                                  offers.push(item);
                                }
                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          if (item.is_default) {
                            /**
                             * - COUNT USER PREVIOUS ORDERS
                             * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              this.store.saveAcceptedPromotion(item); // default discount added directly
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              this.store.saveAcceptedPromotion(item);
                              offers.push(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT
                             * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                          }
                        }
                      }
                    }
                  } else {
                    /**
                     * - IF USER DID NOT SELECT PAYMENT METHOD YET!
                     * **/

                    /*
                      * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                      * */

                    if (item.start_date !== null && item.end_date !== null) {
                      /**
                       * - IF START AND END DATE ASSIGNED
                       */
                      const start_date = new Date(item.start_date);
                      const end_date = new Date(item.end_date);
                      if (currentTime >= start_date && currentTime <= end_date) {
                        if (item.offer_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  this.store.saveAcceptedPromotion(item); // default discount added directly
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  this.store.saveAcceptedPromotion(item);
                                  offers.push(item);
                                }
                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  offers.push(item);
                                }

                                if (item.is_first_order === false) {
                                  offers.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          /**
                           * - NOT TIME GIVEN
                           * **/
                          if (item.is_default) {
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              this.store.saveAcceptedPromotion(item); // default discount added directly
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              this.store.saveAcceptedPromotion(item);
                              offers.push(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              offers.push(item);
                            }

                            if (item.is_first_order === false) {
                              offers.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      /*
                        * - IF START AND END DATE NOT GIVEN.
                        * */
                      if (item.offer_available_time.length > 0) {
                        const dayName = currentTime.getDay();
                        const _times = item.offer_available_time.filter(book => book.day_name === dayName);
                        if (_times.length > 0) {
                          const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                          const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                          if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                this.store.saveAcceptedPromotion(item); // default discount added directly
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                this.store.saveAcceptedPromotion(item);
                                offers.push(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT
                               * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                offers.push(item);
                              }

                              if (item.is_first_order === false) {
                                offers.push(item);
                              }
                            }
                          }
                        } // end time compare
                      } else {
                        if (item.is_default) {
                          /**
                           * - COUNT USER PREVIOUS ORDERS
                           * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            this.store.saveAcceptedPromotion(item); // default discount added directly
                            offers.push(item);
                          }

                          if (item.is_first_order === false) {
                            this.store.saveAcceptedPromotion(item);
                            offers.push(item);
                          }
                        } else {
                          /**
                           * - NOT DEFAULT
                           * - USER HAVE TO ACCEPT DISCOUNT
                           * **/

                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            offers.push(item);
                          }

                          if (item.is_first_order === false) {
                            offers.push(item);
                          }
                        }
                      }
                    }
                  }
                } else {
                  offers = [];
                  this.store.saveAcceptedPromotion({});
                }
              });
            }
          }

          if (discountsData.length > 0) {
            /**
             * COLLECTION AND DELIVERY BOTH= 0
             * COLLECTION = 1
             * DELIVER = 2
             * **/
            const dis = discountsData.filter(item => item.its_type === 1 && totalAmount >= item.discount_eligible_amount && item.is_active === true);
            if (dis.length > 0) {

              dis.forEach(item => {
                if (Number(item.discount_eligible_for) === selected_policy || Number(item.discount_eligible_for) === 0) {
                  /**
                   * - CHECK ELIGIBLE PAYMENT METHODS CASH = 1, CARD = 2, BOTH = 0
                   * - CHECK DISCOUNT START DATE AND END DATE IF NO DATE FIXED THEN IT'S FOR ALL DAY
                   * - CHECK DISCOUNT AVAILABLE TIMES
                   * - CHECK DEFAULT (AUTOMATIC ACCEPT DISCOUNT)
                   * - CHECK IS IT FIRST ORDER BY CUSTOMER
                   * **/

                  if (selected_payment) {
                    if (item.eligible_payment_methods === selected_payment || item.eligible_payment_methods === 0) {
                      /*
                        * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                        * */

                      if (item.start_date !== null && item.end_date !== null) {
                        /**
                         * - IF START AND END DATE ASSIGNED
                         */
                        const start_date = new Date(item.start_date);
                        const end_date = new Date(item.end_date);
                        if (currentTime >= start_date && currentTime <= end_date) {

                          if (item.discount_available_time.length > 0) {

                            const dayName = currentTime.getDay();
                            const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                            if (_times.length > 0) {
                              const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                              const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                              if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                                if (item.is_default) {

                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    if (!sessionStorage.getItem('promoId')) {
                                      this.store.saveAcceptedPromotion(item);
                                    } // default discount added directly
                                    discount.push(item);
                                    return false;
                                  }
                                  if (item.is_first_order === false) {
                                    if (!sessionStorage.getItem('promoId')) {
                                      this.store.saveAcceptedPromotion(item);
                                    }
                                    discount.push(item);
                                  }

                                } else {
                                  /**
                                   * - NOT DEFAULT
                                   * - USER HAVE TO ACCEPT DISCOUNT
                                   * **/

                                  /*** - COUNT USER PREVIOUS ORDERS * **/
                                  if (item.is_first_order && (user && user.total_orders === 0)) {
                                    discount.push(item);
                                    return false;
                                  }
                                  if (item.is_first_order === false) {
                                    discount.push(item);
                                  }
                                }
                              } // end time compare
                            }
                          } else {
                            /**
                             * - NOT TIME GIVEN
                             * **/
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                } // default discount added directly
                                discount.push(item);
                                return false;
                              }
                              if (item.is_first_order === false) {
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }
                                discount.push(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                               * **/


                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                                return false;
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                            }
                          }
                        }
                      } else {
                        /*
                          * - IF START AND END DATE NOT GIVEN (if it is NULL).
                          * - PROCESS WITH TIME ELSE DEFAULT
                          * */

                        if (item.discount_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {
                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  } // default discount added directly
                                  discount.push(item);
                                  return false;
                                }
                                if (item.is_first_order === false) {
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  }
                                  discount.push(item);
                                }

                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                  return false;
                                }
                                if (item.is_first_order === false) {
                                  discount.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          if (item.is_default) {
                            /**
                             * - COUNT USER PREVIOUS ORDERS
                             * **/
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              } // default discount added directly
                              discount.push(item);
                              return false;
                            }
                            if (item.is_first_order === false) {
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                              discount.push(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT
                             * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                              return false;
                            }
                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                          }
                        }
                      }
                    }
                  } else {
                    /**
                     * - IF USER DID NOT SELECT PAYMENT METHOD YET!
                     * **/

                    /*
                      * - EITHER CASH OR CARD * CASH = 1; CARD = 2
                      * */


                    if (item.start_date !== null && item.end_date !== null) {
                      /**
                       * - IF START AND END DATE ASSIGNED
                       */


                      const start_date = new Date(item.start_date);
                      const end_date = new Date(item.end_date);
                      if (currentTime >= start_date && currentTime <= end_date) {

                        if (item.discount_available_time.length > 0) {
                          const dayName = currentTime.getDay();
                          const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                          if (_times.length > 0) {

                            const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                            const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                            if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                              if (item.is_default) {
                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  } // default discount added directly
                                  discount.push(item);
                                  return false;
                                }
                                if (item.is_first_order === false) {
                                  if (!sessionStorage.getItem('promoId')) {
                                    this.store.saveAcceptedPromotion(item);
                                  }
                                  discount.push(item);
                                }

                              } else {
                                /**
                                 * - NOT DEFAULT
                                 * - USER HAVE TO ACCEPT DISCOUNT
                                 * **/

                                /*** - COUNT USER PREVIOUS ORDERS * **/
                                if (item.is_first_order && (user && user.total_orders === 0)) {
                                  discount.push(item);
                                  return false;
                                }
                                if (item.is_first_order === false) {

                                  discount.push(item);
                                }
                              }
                            }
                          } // end time compare
                        } else {
                          /**
                           * - NOT TIME GIVEN
                           * **/
                          if (item.is_default) {
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              } // default discount added directly
                              discount.push(item);
                              return false;
                            }
                            if (item.is_first_order === false) {
                              if (!sessionStorage.getItem('promoId')) {
                                this.store.saveAcceptedPromotion(item);
                              }
                              discount.push(item);
                            }
                          } else {
                            /**
                             * - NOT DEFAULT * - USER HAVE TO ACCEPT DISCOUNT
                             * **/

                            /*** - COUNT USER PREVIOUS ORDERS * **/
                            if (item.is_first_order && (user && user.total_orders === 0)) {
                              discount.push(item);
                              return false;
                            }

                            if (item.is_first_order === false) {
                              discount.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      /*
                        * - IF START AND END DATE NOT GIVEN.
                        * */
                      if (item.discount_available_time.length > 0) {
                        const dayName = currentTime.getDay();
                        const _times = item.discount_available_time.filter(book => book.day_name === dayName);
                        if (_times.length > 0) {
                          const start_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].start_time));
                          const end_time = new Date(Date.parse(currentTime.getFullYear() + '/' + (currentTime.getMonth() + 1) + '/' + currentTime.getDate() + ' ' + _times[0].end_time));
                          if (currentTime >= start_time && currentTime <= end_time || currentTime < start_time) {
                            if (item.is_default) {
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                } // default discount added directly
                                discount.push(item);
                                return false;
                              }
                              if (item.is_first_order === false) {
                                if (!sessionStorage.getItem('promoId')) {
                                  this.store.saveAcceptedPromotion(item);
                                }
                                discount.push(item);
                              }
                            } else {
                              /**
                               * - NOT DEFAULT
                               * - USER HAVE TO ACCEPT DISCOUNT
                               * **/

                              /*** - COUNT USER PREVIOUS ORDERS * **/
                              if (item.is_first_order && (user && user.total_orders === 0)) {
                                discount.push(item);
                                return false;
                              }
                              if (item.is_first_order === false) {
                                discount.push(item);
                              }
                            }
                          }
                        } // end time compare
                      } else {
                        if (item.is_default) {
                          /**
                           * - COUNT USER PREVIOUS ORDERS
                           * **/
                          /*** - COUNT USER PREVIOUS ORDERS * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            } // default discount added directly
                            discount.push(item);
                            return false;
                          }
                          if (item.is_first_order === false) {
                            if (!sessionStorage.getItem('promoId')) {
                              this.store.saveAcceptedPromotion(item);
                            }
                            discount.push(item);
                          }
                        } else {
                          /**
                           * - NOT DEFAULT
                           * - USER HAVE TO ACCEPT DISCOUNT
                           * **/

                          /*** - COUNT USER PREVIOUS ORDERS * **/
                          if (item.is_first_order && (user && user.total_orders === 0)) {
                            discount.push(item);
                            return false;
                          }
                          if (item.is_first_order === false) {
                            discount.push(item);
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
          const availableOffersDiscount = discount.concat(offers);
          resolve(availableOffersDiscount);
        }, 200);
      }
    });
  }


  getASAPTime(deliveryTime) {
    const currentTime = new Date();
    return this.changeTimeFormat(new Date(currentTime.getTime() + Number(deliveryTime) * 60000));
  }

  stringTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  goToWebLink(data) {
    var url = String(data).replace(/^(https?:\/\/)?(www\.)?/, 'http://');
    window.open(url, '_blank',);
  }

  generateCartHashKey(dishData) {
    let dish_extra_id = '';
    if (dishData['dish_extra']) {
      dishData['dish_extra'].forEach(grp => {
        dish_extra_id = dish_extra_id.concat(grp['_id']);
        grp['option'].forEach(opt => {
          dish_extra_id = dish_extra_id.concat(opt['_id']);
        });
      });
    }
    const checksum_data = {
      _id: dishData['_id'],
      dish_id: dishData['dish_id'],
      dish_instruction: dishData['dish_instruction'].trim().toLowerCase(),
      dish_extra_id: dish_extra_id
    }
    const md5 = new Md5();
    let checksum = md5.appendStr(JSON.stringify(checksum_data)).end();
    return checksum;
  }

  generateCartDataHashKey(cartData) {
    const md5 = new Md5();
    let checksum = md5.appendStr(JSON.stringify(cartData)).end();
    sessionStorage.setItem('generated_cart_data', JSON.stringify(checksum));
  }

  getAvailablePromotions(subTotalExcludeOffer, discountsData, offersData) {
    return new Promise((resolve) => {
      let eligiblePromotionList = [];
      this.checkPromotionEligibility(subTotalExcludeOffer, discountsData, offersData).then((result : any) => {
        result.forEach(item => {
          eligiblePromotionList.push(item);
        });
        this.store.saveAvailablePromotion(eligiblePromotionList);
        resolve('check & save');
      });
    });
  }

  getAcceptedDiscount(subTotalExcludeOffer, AcceptedData) {
    return new Promise((resolve) => {
      /** its_type=1=discount, its_type=2=offer **/
      let selected_policy;
      let selected_payment;
      this.store.selected_policy.subscribe((result: any) => {
        if (result && result['policy']) {
          selected_policy = result['policy'] === 'COLLECTION' ? 1 : 2;
        } else {
          selected_policy = 2;
        }
      });
      this.store.paymentMethod.subscribe((result: any) => {
        if(result && result['is_active']) {
          selected_payment = result['paymentType'] === 'CARD' ? 2 : 1
        }
      });
      switch (AcceptedData.its_type) {
        case 1:
          if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods === selected_payment || AcceptedData.eligible_payment_methods === 0)
            && subTotalExcludeOffer >= AcceptedData.discount_eligible_amount
          ) {
            /**
             * SET ACCEPTED DISCOUNT DATA
             * Eligible policy either collection/delivery or both
             * col=1, del =2, both=0,
             * Eligible payment method selected
             * cash=1, card 2, both 0
            **/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && !selected_payment
            && subTotalExcludeOffer >= AcceptedData.discount_eligible_amount
          ) {
            /**-----SET ACCEPTED DISCOUNT DATA UNTIL USER SELECT PAYMENT METHOD-----**/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            && subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) !== 0)
            && !selected_payment
            || subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED policy NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.discount_eligible_for) || Number(AcceptedData.discount_eligible_for) !== 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if(
            subTotalExcludeOffer < AcceptedData.discount_eligible_amount
          ) {
            this.store.saveAcceptedPromotion({});
          }
          break;

        case 2:
          if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods === selected_payment || AcceptedData.eligible_payment_methods === 0)
            && subTotalExcludeOffer >= AcceptedData.offer_eligible_amount
          ) {
            /**
             * SET ACCEPTED DISCOUNT DATA
             * Eligible policy either collection/delivery or both
             * col=1, del =2, both=0,
             * Eligible payment method selected
             * cash=1, card 2, both 0
            **/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && !selected_payment
            && subTotalExcludeOffer >= AcceptedData.offer_eligible_amount
          ) {
            /**SET ACCEPTED DISCOUNT DATA UNTIL USER SELECT PAYMENT METHOD**/
            resolve(AcceptedData);
          } else if (
            (selected_policy === Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) === 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) !== 0)
            && !selected_payment
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED policy NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if (
            (selected_policy !== Number(AcceptedData.offer_eligible_for) || Number(AcceptedData.offer_eligible_for) !== 0)
            && selected_payment
            && (AcceptedData.eligible_payment_methods !== selected_payment || AcceptedData.eligible_payment_methods !== 0)
            || subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            /**
             * RESET ACCEPTED DISCOUNT DATA
             * SELECTED PAYMENT METHOD NOT ELIBIBLE
            **/
            this.store.saveAcceptedPromotion({});
          } else if(
            subTotalExcludeOffer < AcceptedData.offer_eligible_amount
          ) {
            this.store.saveAcceptedPromotion({});
          }
          break;

      }
    });
  }


}
