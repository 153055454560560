import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {HttpService} from 'src/app/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
  user_id: any;
  cookiePolicyModal: boolean;

  constructor(
    public router: Router,
    private httpService: HttpService,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {
    // this.cookiePolicyModal = true;
    if (this.platformId === 'browser') {// find user
      let user = localStorage.getItem('user');
      if(user) this.user_id = JSON.parse(localStorage.getItem('user'))._id;
    }
  }

  ngOnInit() {
    if (this.platformId === 'browser') {// find cookie
      let isAcceptCookiePolicy = localStorage.getItem('cookie');
      if(!isAcceptCookiePolicy) this.cookiePolicyModal = true;
    }
  }

  goToPrivacyPolicy() {
    this.router.navigateByUrl('privacy-policy').then(() => {
      localStorage.setItem('cookie', 'yes');
      this.closeModal();
    })
  }

  acceptCookie() {
    this.httpService.addUserCookiePolicy({user_id: this.user_id}).subscribe();
    if (this.platformId === 'browser') {
      localStorage.setItem('cookie', 'yes');
      this.closeModal();   
    }
  }

  closeModal() {
    this.cookiePolicyModal = false;
  }

}
