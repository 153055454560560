import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { tokenNotExpired } from 'angular2-jwt';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
// for social
import { SocialAuthService } from 'angularx-social-login';
import {FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
// services
import { StoreService } from 'src/app/services/store.service';
import { HttpService } from 'src/app/services/http.service';

@Injectable()
export class LoginService {
  loading = Swal.mixin({
    allowOutsideClick: false, allowEscapeKey: false
  });
  token: string;
  user: any;
  loggedIn: boolean = false;
  
  timer: number;
  uId: string;

  constructor(
    private storeService: StoreService,
    private httpService: HttpService,
    private socialAuthService: SocialAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {
    this.storeService.user.subscribe(res => {
      if (res) {
        this.user = res.data;
        this.loggedIn = res.isLogged;
        this.token = res.token;
      }
    }, err => {
      // console.log(err);
    });
    this.socialLoginResponse();
  }

  IsloggedIn() {
    if (this.platformId === 'browser') {
      return tokenNotExpired();
    }
  }

  emailOrMobileLogin(data) {
    this.loading.fire({
      customClass: { container: 'progress-swal' },
      title: 'Please Wait...',
      onOpen: () => {
        Swal.showLoading();
      }
    });
    this.httpService.loginService(data).subscribe(res => {
      let _res: any = {};
      _res = res;
      if(_res['data']) {
        _res['data'].user_type = 'normal_user';
      }
      this.setLoginInfo(_res);
    }, err => {
      this.loading.fire({
        icon: 'error', title: 'Oops!', text: 'Please try again with correct credentials.',
        onOpen: () => {
          Swal.hideLoading();
        }
      });
    });
  }

  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }


  socialLoginResponse() {
    // this.loading.fire({
    //   customClass: {container: 'progress-swal'},
    //   title: 'Please Wait...',
    //   onOpen: () => {
    //     Swal.showLoading();
    //   }
    // });
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.httpService.socialUserSignUp({
          first_name: user.firstName,
          last_name: user.lastName,
          photo: user.photoUrl,
          email: user.email,
          providerId: user.id,
          provider: user.provider
        }).subscribe(result => {
          if (result) {
            let _res: any = {};
            _res = result;
            if(_res['data']) {
              _res['data'].user_type = 'social_user';
            }
            this.setLoginInfo(_res);
          } else {
            this.loading.fire({
              icon: 'error', title: 'Oops!', text: 'Something went wrong!',
              onOpen: () => {
                Swal.hideLoading();
              }
            });
          }
        });
      } else {
        Swal.fire('Warning!', 'Social login failed. Please login with email and password.', 'error');
        if (this.loading.isVisible()) {
          this.loading.close();
        }
      }
    }, err => {
      if (this.loading.isVisible()) {
        this.loading.close();
      }
    });
  }

  setLoginInfo(result) {
    if ((result.status === true || result.status === 'success') && result.token !== '') {
      this.loggedIn = true;
      this.user = result['data'];
      if(!this.user.isVerified) {
        this.verifyAccount();
      }
      this.storeService.saveLoginData({ isLogged: true, data: result['data'], token: result['token'] });
      if (this.loading.isVisible()) {
        this.loading.close();
      }
      this.storeService.pageRedirection(this.user);
    } else {
      this.storeService.saveLoginData({isLogged: false});
      this.loading.fire({
        title: 'Oops!',
        text: result['msg'],
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#212529',
        confirmButtonText: result['flag'] === 0 ? 'Sign Up' : 'Sign In with OTP',
        cancelButtonText: 'Close',
        onOpen: () => {
          Swal.hideLoading();
        }
      }).then((value) => {
        if (value.isConfirmed) {
          if(result['flag'] === 0) {
            this.router.navigate(['/sign-up']);
          } else {
            this.router.navigate(['/forget-password'], {
              state: {for: 'OTP Login'}
            });
          }
        }
      });
    }
  }


  logOut(): void {
    this.loading.fire({
      customClass: { container: 'progress-swal' },
      title: 'Please Wait...',
      onOpen: () => {
        Swal.showLoading();
      }
    });
    if(this.user['user_type'] === 'social_user') {
      this.socialAuthService.signOut();
    }
    this.userSessionOut();
    this.router.navigateByUrl('sign-in');
    if (this.loading.isVisible()) {
      this.loading.close();
    }
  }

  userSessionOut() {
    this.storeService.saveLoginData({ isLogged: false });
    this.storeService.saveDeliveryAddress({});
    this.storeService.setDeliveryChargeInfo({});
  }

  verifyAccount() {
    const data = {mobile_no: this.user.mobile_no};
    this.httpService.sendForgotOtp(data).subscribe(res => {
      if (res['status'] === 'sms_sent') {
        this.uId = res['uid'];
        this.timer = (1000 * 60) * 5;
        this.openOTPModal();
      }
    }, err => {
    });
  }

  openOTPModal() {
    let timerInterval;   
    Swal.fire({
      title: 'Verify Account',
      html: `We have sent a SMS to <strong>${this.user.mobile_no}</strong> with your varification code.
            Please enter your code within <b></b>`,
      input: 'text',
      inputPlaceholder: 'Verification code *',
      confirmButtonText: 'Verify',
      showCancelButton: true,
      cancelButtonText: 'Skip now',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      timer: this.timer,
      timerProgressBar: true,
      inputValidator: (value) => {
        if (!value) return 'Please enter your Code.'
        if (value && value.length < 4) return 'Please enter your valid Code.'
      },
      preConfirm: (value) => {
        let data = {
          email: this.user.email,
          verification_token: value,
          uid: this.uId
        }
        this.httpService.verifyForgetPasswordToken(data).subscribe(res => {
          this.uId = undefined;
          if (res['status'] === 'success') {
            let _res: any = {};
            _res = res;
            if(_res['data']) {
              _res['data'].user_type = 'normal_user';
            }
            this.setLoginInfo(_res);
          } else {
            this.resendOTPConfirmationModal(res['message']);
          }
        });
      },
      onOpen: () => {
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              let min = Math.floor((Swal.getTimerLeft() / (1000 * 60)) % 60);
              let sec = Math.floor((Swal.getTimerLeft() / 1000) % 60);
              b.textContent = (min + ':' + sec);
            }
          }
          this.timer = Swal.getTimerLeft();
        }, 1000);
      },
      onClose: () => {
        if(this.timer > 0) this.countdownOTPTime();
        clearInterval(timerInterval);
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        this.resendOTPConfirmationModal();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.skipOTPConfirmationModal();
      }
    })
  }

  skipOTPConfirmationModal() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You can't place order untill your acoount varification",
      icon: 'warning',
      confirmButtonText: 'Yes, Skip Now',
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        this.openOTPModal();
      }
    })
  }

  resendOTPConfirmationModal(msg?: string) {
    Swal.fire({
      title: msg ? 'Notice' : 'Resend Again?',
      text: msg ? (msg + '. ' + 'Want to try again?') : 'You want to send OTP on your mobile again?',
      icon: msg ? 'info' : 'question',
      confirmButtonText: 'Resend OTP',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.verifyAccount();
      }
    });
  }

  countdownOTPTime() {
    if(this.timer > 0) {
      let timerInterval = setInterval(() => {
        this.timer = this.timer - 1000;
        if(this.timer <= 0) {
          clearInterval(timerInterval);
          this.uId = undefined;
        }
      }, 1000);
    }
  }

}
