import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngFilter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], value: any, objectKey: string): any {
    if(!array) return [];
    if(!value) return array;
    return array.filter(item => item[objectKey].includes(value));    
  }

}

