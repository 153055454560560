import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { config } from 'src/app/config';

@Pipe({
  name: 'CustomCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  currencyCode: string;
  display: string;
  digitsInfo: string;

  constructor(private currencyPipe: CurrencyPipe) {
    if (config.RES_LOCATION === 'BD') {
      this.currencyCode = 'BDT';
      this.display = 'symbol-narrow';
      this.digitsInfo = '1.0-0';
    } else if(config.RES_LOCATION === 'GB') {
      this.currencyCode = 'GBP';
      this.display = 'symbol';
      this.digitsInfo = '1.2-2';
    }
  }

  transform(value: any, currencyCode = this.currencyCode, display = this.display, digitsInfo = this.digitsInfo): string {
    if (!value || value === null) {
      return this.currencyPipe.transform(0, currencyCode, display, digitsInfo)
    };
    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo);
  }

}
