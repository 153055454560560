import {config} from '../config';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {StoreService} from './store.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { shareReplay } from 'rxjs/internal/operators';


@Injectable()
export class HttpService {
  private API = config.API;
  private API2 = config.API2;
  private RESTAURANT_ID = config.RESTAURANT_ID;
  public token: any;
  private RESTAURANT_NAME: any;
  private domain_name: any;
  private user_id: any;

  aboutPageData: Observable<any>;
  dealPageData: Observable<any>;
  menuPageData: Observable<any>;
  reservationPageData: Observable<any>;
  reviewPageData: Observable<any>; 
  galleryPageData: Observable<any>; 
  contactPageData: Observable<any>; 
  eventsPageData: Observable<any>; 
  orderPageData: Observable<any>; 
  awardPageData: Observable<any>; 
  blogPageData: Observable<any>; 
  teamPageData: Observable<any>; 
  getArticlesListByRestaurantData: Observable<any>;

  getRestaurantContentData: Observable<any>;
  getRestaurantData: Observable<any>;
  getMenuData: Observable<any>;
  mostPopularDishesData: Observable<any>;
  getDeliveryAreasData: Observable<any>;
  getPaymentMethodData: Observable<any>;

  getReviewsData: Observable<any>;
  bookingDealsData: Observable<any>;
  UserReviewsData: Observable<any>;

  constructor(
    private http: HttpClient,
    private store: StoreService
  ) {
    this.store.user.subscribe(res => {
      if (res) {
        if(res.isLogged) {
          this.token = res.token;
          this.user_id = res.data._id;
        } else {
          this.token = undefined;
          this.user_id = undefined;
        }
      }
    });

    this.store.restaurantData.subscribe(data => {
      if (Object.getOwnPropertyNames(data).length !== 0) {
        this.RESTAURANT_NAME = data.restaurant_data.restaurant_name;
        this.domain_name = data.restaurant_data.domain;
      }
    });
  }


  getRestaurantContent(): Observable<any> {
    let url = this.API + 'getRestaurantContent/' + this.RESTAURANT_ID;
    if(this.getRestaurantContentData) {
      return this.getRestaurantContentData;
    } else {
      this.getRestaurantContentData = this.http.get<any>(url).pipe(shareReplay());
      return this.getRestaurantContentData;
    }
  }

  getRestaurantInfo(): Observable<any> {
    let url = this.API + 'getRestaurant/' + this.RESTAURANT_ID;
    if(this.getRestaurantData) {
      return this.getRestaurantData;
    } else {
      this.getRestaurantData = this.http.get<any>(url).pipe(shareReplay());
      return this.getRestaurantData;
    }
  }

  getPageDataById_V2(pageId): Observable<any> {
    let url = this.API + 'getPageDataById_V2/' + this.RESTAURANT_ID + '/' + pageId;
    if(pageId === 2) {
      if(this.aboutPageData) {
        return this.aboutPageData;
      } else {
        this.aboutPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.aboutPageData;
      }
    } else if(pageId === 3) {
      if(this.dealPageData) {
        return this.dealPageData;
      } else {
        this.dealPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.dealPageData;
      }
    } else if(pageId === 4) {
      if(this.menuPageData) {
        return this.menuPageData;
      } else {
        this.menuPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.menuPageData;
      }
    } else if(pageId === 5) {
      if(this.reservationPageData) {
        return this.reservationPageData;
      } else {
        this.reservationPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.reservationPageData;
      }
    } else if(pageId === 6) {
      if(this.reviewPageData) {
        return this.reviewPageData;
      } else {
        this.reviewPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.reviewPageData;
      }
    } else if(pageId === 7) {
      if(this.galleryPageData) {
        return this.galleryPageData;
      } else {
        this.galleryPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.galleryPageData;
      }
    } else if(pageId === 8) {
      if(this.contactPageData) {
        return this.contactPageData;
      } else {
        this.contactPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.contactPageData;
      }
    } else if(pageId === 9) {
      if(this.eventsPageData) {
        return this.eventsPageData;
      } else {
        this.eventsPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.eventsPageData;
      }
    } else if(pageId === 10) {
      if(this.orderPageData) {
        return this.orderPageData;
      } else {
        this.orderPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.orderPageData;
      }
    } else if(pageId === 11) {
      if(this.awardPageData) {
        return this.awardPageData;
      } else {
        this.awardPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.awardPageData;
      }
    } else if(pageId === 12) {
      if(this.blogPageData) {
        return this.blogPageData;
      } else {
        this.blogPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.blogPageData;
      }
    } else if(pageId === 13) {
      if(this.teamPageData) {
        return this.teamPageData;
      } else {
        this.teamPageData = this.http.get<any>(url).pipe(shareReplay());
        return this.teamPageData;
      }
    }
  }

  getMenu(): Observable<any> {
    let url = this.API + 'getMenu/' + this.RESTAURANT_ID;
    if(this.getMenuData) {
      return this.getMenuData;
    } else {
      this.getMenuData = this.http.get<any>(url).pipe(shareReplay());
      return this.getMenuData;
    }
  }

  addParcelInfo(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API2 + 'auth/addParcelInfo';
    return this.http.post(url, data, {headers: headers});
  }


  getParcelAddress(userId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get<any>(config.API2 + 'auth/getParcelAddress/' + userId, {headers: headers});
  }


  getGroupDishData(ids) {
    const data = {'ids': ids};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.post<any>(config.API2 + 'getGroupDishByIds', data, {headers: headers});
  }

  mostPopularDishes(): Observable<any> {
    let url = this.API2 + 'mostPopularDishes/' + this.RESTAURANT_ID;
    if(this.mostPopularDishesData) {
      return this.mostPopularDishesData;
    } else {
      this.mostPopularDishesData = this.http.get<any>(url).pipe(shareReplay());
      return this.mostPopularDishesData;
    }
  }

  reservationService(data): Observable<any> {
    data.restaurant_id = this.RESTAURANT_ID;
    data.restaurant_name = this.RESTAURANT_NAME;
    /*return this.http.post(config.API2 + 'restaurant_reservations/create', data);*/

    const reqx = new HttpRequest('POST', config.API2 + 'restaurant_reservations/create', data, {
      reportProgress: true,
    });

    return this.http.request(reqx);
  }

  loginService(body: any) {
    const url = config.API2 + 'loginAuth';
    return this.http.post(url, body);
  }
  checkExistUser(body: any) {
    const url = config.API2 + 'checkUserExistence';
    return this.http.post(url, body);
  }

  registrationService(body: any) {
    body.registered_restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'registration';
    return this.http.post(url, body); // ...using post request
  }

  socialUserSignUp(body: any) {
    body.registered_restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'socialUserSignUp';
    return this.http.post(url, body); // ...using post request
  }

  verifyGuestUserToken(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'verifyGuestUserToken';
    return this.http.post(url, data);
  }

  guestLogin(body: any) {
    body.registered_restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'guestLogin';
    return this.http.post(url, body);
  }

  /*//auth req*/
  postOrder(data): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    data.restaurant_id = this.RESTAURANT_ID;
    data.restaurant_name = this.RESTAURANT_NAME;
    const reqx = new HttpRequest('POST', config.API2 + 'auth/saveOrder', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  /*//auth req*/
  updateUserInfo(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API2 + 'auth/user/update';
    return this.http.put(url, data, {headers: headers});
  }

  addOrderAddress(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API2 + 'auth/user/addOrderAddress';
    return this.http.put(url, data, {headers: headers});
  }

  /*//auth req*/
  checkPostCode(postcode) {
    return this.http.get('https://api.postcodes.io/postcodes/' + postcode);
  }

  getAreaCode(postcode) {
    return this.http.get('https://api.postcodes.io/postcodes/' + postcode);
  }

  /*//auth req*/
  getOrderDetails(order_id): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/getOrderDetails/' + order_id, {headers: headers});
  }

  trackOrder(order_id): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/trackOrder/' + order_id, {headers: headers});
  }

  getUserActivity(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/user/activity/' + this.user_id + '/' + this.RESTAURANT_ID, {headers: headers});
  }

  getUserReservation(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/getReservations/' + this.user_id + '/' + this.RESTAURANT_ID, {headers: headers});
  }


  retriveAddressListByPostcode(postcode) {
    return this.http.get(
      `${config.API2}findWithPostcode/${postcode}`
    );
  }

  retriveAddressByPostcode(postcode, address_line1) {
    return this.http.get(
      `${config.API2}findWithPostcodeAndHouse/${postcode}/${address_line1}`
    );
  }

  getDeliveryCharge(area_code, postcode) {
    return this.http.get(config.API2 + 'getDeliveryCharge/' + this.RESTAURANT_ID + '/' + area_code + '/' + postcode);
  }

  getPageContent(page_id) {
    return this.http.get(config.API2 + 'getPageContent/' + this.RESTAURANT_ID + '/' + page_id);
  }

  getGetPaymentMethod() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    let url = this.API2 + 'auth/getPaymentMethod/' + this.RESTAURANT_ID;
    if(this.getPaymentMethodData) {
      return this.getPaymentMethodData;
    } else {
      this.getPaymentMethodData = this.http.get<any>(url, {headers: headers}).pipe(shareReplay());
      return this.getPaymentMethodData;
    }
  }

  /*//auth req*/
  getUserOrders() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/customer_orders/' + this.user_id + '/' + this.RESTAURANT_ID, {headers: headers});
  }

  getReOrderData(orderId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'getReOrderData?_id=' + orderId, {headers: headers}); 
  }

  createPayment(body: any) {
    body.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'createPaymentWithCard';
    return this.http.post(url, body);
  }

  getPayPalUrl(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.post(config.API2 + 'auth/PayPal_payment', data, {headers: headers});
  }


  resendVerificationCode(data) {
    return this.http.post(config.API2 + 'resendVerificationCode', data);
  }

  changeMobileNumber(data) {
    return this.http.post(config.API2 + 'changeMobileNumber', data);
  }

  postUserReview(data): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    data.restaurant_id = this.RESTAURANT_ID;
    data.user_id = this.user_id;
    return this.http.post(config.API2 + 'auth/saveUserReview', data, {headers: headers});
  }

  getRestaurantReviews() {
    let url = this.API2 + 'getReviews/' + this.RESTAURANT_ID;
    if(this.getReviewsData) {
      return this.getReviewsData;
    } else {
      this.getReviewsData = this.http.get<any>(url).pipe(shareReplay());
      return this.getReviewsData;
    }
  }

  getUserReview() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.get(config.API2 + 'auth/getUserReview/' + this.user_id + '/' + this.RESTAURANT_ID, {headers: headers});
  }

  sendContactFormEmail(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'sendContactFormEmail';
    return this.http.post(url, data);
  }

  sendForgotEmail(data) {
    data.restaurant_name = this.RESTAURANT_NAME;
    data.domain = this.domain_name;
    // data.version = 2;
    const url = config.API2 + 'sendForgetPasswordEmail';
    return this.http.post(url, data);
  }

  sendForgotOtp(data) {
    const url = config.API2 + 'sendForgotPassOtp';
    return this.http.post(url, data);
  }

  verifyForgetPasswordToken(data) {
    const url = config.API2 + 'verify_forget_password';
    return this.http.post(url, data);
  }

  changePassword(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.put(config.API2 + 'auth/reset_password', data, {headers: headers});
  }

  modifyPassword(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.put(config.API2 + 'auth/user/change_password', data, {headers: headers});
  }

  sendOrderConfirmationEmail(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    return this.http.post(config.API2 + 'auth/sendConfirmationEmail', data, {headers: headers});
  }

  getBookingDeals() {
    let url = this.API2 + 'bookingDeals/' + this.RESTAURANT_ID;
    if(this.bookingDealsData) {
      return this.bookingDealsData;
    } else {
      this.bookingDealsData = this.http.get<any>(url).pipe(shareReplay());
      return this.bookingDealsData;
    }
  }

  userReviews() {
    let url = this.API2 + 'UserReviews/' + this.RESTAURANT_ID;
    if(this.UserReviewsData) {
      return this.UserReviewsData;
    } else {
      this.UserReviewsData = this.http.get<any>(url).pipe(shareReplay());
      return this.UserReviewsData;
    }
  }

  getDeliveryAreas() {
    let url = this.API2 + 'getDeliveryAreas/' + this.RESTAURANT_ID;
    if(this.getDeliveryAreasData) {
      return this.getDeliveryAreasData;
    } else {
      this.getDeliveryAreasData = this.http.get<any>(url).pipe(shareReplay());
      return this.getDeliveryAreasData;
    }
  }

  getDeliveryDistance(origin, destination, sub_total, area_code) {
    const data = {
      restaurant_id: this.RESTAURANT_ID,
      origin: origin,
      destination: destination,
      area_code: area_code,
      min_delivery_amount: sub_total
    };

    return this.http.post(config.API2 + 'getDeliveryInfo', data);
  }

  getGoogleReviews(postcode) {
    return this.http.get(config.API2 + 'getGoogleReview/' + postcode);
  }

  eventDetails(_id): Observable<any> {
    return this.http.get(config.API2 + 'event/' + _id);
  }

  getBlogListData() {
    let url = this.API2 + 'getArticlesListByRestaurant/' + this.RESTAURANT_ID;
    if(this.getArticlesListByRestaurantData) {
      return this.getArticlesListByRestaurantData;
    } else {
      this.getArticlesListByRestaurantData = this.http.get<any>(url).pipe(shareReplay());
      return this.getArticlesListByRestaurantData;
    }
  }
  getBlogDataById(_id) {
    return this.http.get(config.API2 + 'getArticleById/' + _id);
  }

  registerEvent(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'registerEvent';
    return this.http.post(url, data);
  }

  createCardTransaction(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'createTransaction';
    return this.http.post(url, data);
  }

  addUserCookiePolicy(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'addUserCookiePolicy';
    return this.http.post(url, data);
  }

  addUserSubscribe(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API2 + 'addUserSubscribe';
    return this.http.post(url, data);
  }


  reconcile_payment(unique_id) {
    return this.http.get(config.API2 + 'reconcile/' + unique_id);
  }

  createPaymentIntent(payload) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post('api/create-payment-intent', payload, {headers: headers});
  }

  updateStripePaymentStatus(payload, oId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API2 + 'auth/updateStripePaymentStatus?_id=' + oId;
    return this.http.post(url, payload, {headers: headers});
  }

  validateUserToken() {
    const url = config.API2 + 'validateUserToken?token=' + this.token;
    return this.http.get(url);
  }

  
}


