import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Observable';
declare var $: any;
//services
import {StoreService} from 'src/app/services/store.service';
import {SharedService} from 'src/app/services/shared.service';
import {SEOService} from 'src/app/services/seo.service';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, AfterViewChecked {
  cartData: Observable<any>;
  data: Observable<any>;
  user: Observable<any>;
  totalItem: number;
  schedule: Observable<any>;
  selected_policy: any;
  imgSize: number = 60;

  @ViewChild('logo') logo: ElementRef;
  
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private storeService: StoreService,
    private sharedService: SharedService,
    private seoService: SEOService,
    private analyticsService: AnalyticsService
  ) {
    /**RESTORE POLICY IN REDUX STORE UPON REFRESH**/
    this.storeService.storeExistingSelectedPolicy(); // restore existing selected policy
    this.storeService.storeExistingDeliveryTime(); // restore existing delivery time
    this.storeService.setExistingPaymentMethod(); // restore existing delivery time
    this.storeService.storeExistingCartData(); // restore existing cart data
    this.storeService.storeExistingAvailablePromotion(); // restore existing available promotion data
    this.storeService.storeExistingAcceptedPromotion(); // restore existing accepted promotion data
    this.storeService.storeExistingOrderNotes(); // restore existing accepted promotion data
    this.storeService.storeExistingLoginData(); // restore existing accepted promotion data

    this.schedule = this.storeService.schedule;
    this.data = this.storeService.restaurantData;
    this.user = this.storeService.user;

    /**CHECK RESTAURANT INFORMATION IN REDUX STORE THEN, GENERATE RESTAURANT SCHEDULE**/
    this.data.subscribe(result => {
      if (Object.getOwnPropertyNames(result).length !== 0) {
        this.sharedService.scheduleProcessing();
        
        this.seoService.setPageTitle(result.restaurant_content.home_page.seo_page_title);// set page title
        this.seoService.addMetaTag(result.restaurant_content.home_page.seo_meta); // meta data
        this.analyticsService.init(result.restaurant_content.home_page.google_analytics);
        this.analyticsService.createSchema(result.restaurant_content.home_page.schema);
      }
    });
    /**END**/
  }



  ngOnInit() {
    
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      $(document).click(function (event) {
        var clickover = $(event.target);
        var $navbar = $("#top-menu .navbar-collapse");               
        var _opened = $navbar.hasClass("show");
        if (_opened === true && (clickover.hasClass("nav-link") || clickover.hasClass("btn") || clickover.hasClass("overlay"))) {      
            $navbar.collapse('hide');
            document.body.classList.remove('active-nav');
        }
      });
    }
  }

  ngAfterViewChecked() {
    if (this.platformId === 'browser') {
      let imgHeight = this.logo.nativeElement.clientHeight;
      if(imgHeight && imgHeight > 30) {
        this.imgSize = imgHeight;
        this.cdr.detectChanges();
      }
    }
  }


  bodyFixed() {
    if (this.platformId === 'browser') {
      document.body.classList.toggle('active-nav');
    }
  }
}

