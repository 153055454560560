export const VIEW_ITEM_MODAL = 'VIEW_ITEM_MODAL';

export function reducer(state: any = {}, action) {
  switch (action.type) {
    case 'VIEW_ITEM_MODAL':
      return action.payload;
    default:
      return state;
  }
}
