import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
//services
import {AuthGuard} from 'src/app/services/auth-guard.service';

const routes: Routes = [
  {path: '', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule)},
  // {path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
  {path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
  {path: 'about-us', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)},
  {path: 'deals', loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsModule)},
  {path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule)},
  {path: 'reviews', loadChildren: () => import('./pages/review/review.module').then(m => m.ReviewModule)},
  {path: 'events', loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule)},
  {path: 'awards', loadChildren: () => import('./pages/awards/awards.module').then(m => m.AwardsModule)},
  {path: 'gallery', loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryPageModule)},
  {path: 'contact-us', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)},
  {path: 'blogs', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)},
  {path: 'our-team', loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule)},

  {path: 'reservation', loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationModule)},
  {path: 'order-online', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule)},

  {path: 'sign-in', loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then(m => m.SignInModule)},
  {path: 'sign-up', loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpModule)},
  {path: 'verify-account/:uid', loadChildren: () => import('./pages/auth/verify-account/verify-account.module').then(m => m.VerifyAccountModule)},
  {path: 'forget-password', loadChildren: () => import('./pages/auth/forget-pass/forget-pass.module').then(m => m.forgetPassModule)},
  {path: 'reset-password/:token', loadChildren: () => import('./pages/auth/forget-pass/forget-pass.module').then(m => m.forgetPassModule)},

  {path: 'my-account', loadChildren: () => import('./pages/user/user-account/user-account.module').then(m => m.UserAccountModule), canActivate: [AuthGuard]},
  {path: 'my-setting', loadChildren: () => import('./pages/user/user-setting/user-setting.module').then(m => m.UserSettingModule), canActivate: [AuthGuard]},
  {path: 'my-order', loadChildren: () => import('./pages/user/user-order/user-order.module').then(m => m.UserOrderModule), canActivate: [AuthGuard]},
  {path: 'user-order-track/:order_id', loadChildren: () => import('./pages/user/user-order-track/user-order-track.module').then(m => m.UserOrderTrackModule), canActivate: [AuthGuard]},
  {path: 'my-reservation', loadChildren: () => import('./pages/user/user-reservation/user-reservation.module').then(m => m.UserReservationModule), canActivate: [AuthGuard]},
  {path: 'my-review', loadChildren: () => import('./pages/user/user-review/user-review.module').then(m => m.UserReviewModule), canActivate: [AuthGuard]},
  {path: 'my-favourite', loadChildren: () => import('./pages/user/user-favourite/user-favourite.module').then(m => m.UserFavouriteModule), canActivate: [AuthGuard]},

  {path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule), canActivate: [AuthGuard]},
  {path: 'card-payment', loadChildren: () => import('./pages/stripe-payment/stripe-payment.module').then(m => m.StripePaymentModule), canActivate: [AuthGuard]},

  {path: 'privacy-policy', loadChildren: () => import('./pages/others/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)},
  {path: 'terms-conditions', loadChildren: () => import('./pages/others/terms-condition/terms-condition.module').then(m => m.TermsConditionModule)},
  {path: 'faq', loadChildren: () => import('./pages/others/faq/faq.module').then(m => m.FaqModule)},
  {path: 'error-page', loadChildren: () => import('./pages/others/error-page/error-page.module').then(m => m.ErrorPageModule)},

  {path: '**', loadChildren: () => import('./pages/others/error-page/error-page.module').then(m => m.ErrorPageModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
