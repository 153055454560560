<div class="wrapper">
  <div class="loading-wrap" *ngIf="loading">
    <div class="splash-screen">
      <div class="splash-loader"></div>
    </div>
  </div>
  <div class="content-wrap">
    <app-header></app-header>
    <div class="content-body">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <app-checkout-bar></app-checkout-bar>
  <app-cookie-policy></app-cookie-policy>
</div>
