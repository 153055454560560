import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-checkout-bar',
  templateUrl: './checkout-bar.component.html',
  styleUrls: ['./checkout-bar.component.scss']
})
export class CheckoutBarComponent implements OnInit {
  data: Observable<any>;
  path: string;
  user: any;
  mobileBar = ['', 'home', 'about-us', 'our-team', 'deals', 'menu', 'reviews', 'awards', 'events', 'blogs', 'gallery', 'contact-us'];

  constructor(
    private storeService: StoreService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.path = event.url.replace('/', '');
    });
  }

  ngOnInit() {
    this.data = this.storeService.restaurantData;
  }


  hasMatchPath(routePath) {
    if (this.platformId === 'browser') {
      let x = this.mobileBar.includes(routePath);
      let footbar = <HTMLElement>document.querySelector('#footbar');
      if(x && this.platformId === 'browser' && window.innerWidth < 1024 && footbar) {
        footbar.style.marginBottom = '52px';
      } else {
        footbar.style.marginBottom = '0';
      }
      return x;
    }
  }
  

}
