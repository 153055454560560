import { NgModule } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
//pipes
import { SafePipe } from './pipes/safe.pipe';
import { LimitPipe } from './pipes/limit.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { SpecialCharPipe } from './pipes/special-char.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { InArrayPipe } from './pipes/in-array.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PromoFilterPipe } from './pipes/promoFilter.pipe';
import { pictureSrcsetPipe } from './pipes/picture-srcset.pipe';
import { ArraySortPipe } from './pipes/sort.pipe';
//directives
import { ToggleShowHidePassDirective } from './directives/toggle-show-hide-pass.directive';

@NgModule({
    declarations: [
        //pipes
        SafePipe, LimitPipe, SplitPipe, SpecialCharPipe, CustomCurrencyPipe, InArrayPipe, FilterPipe, PromoFilterPipe, pictureSrcsetPipe, ArraySortPipe,
        //directives
        ToggleShowHidePassDirective
    ],
    exports: [
        //pipes
        SafePipe, LimitPipe, SplitPipe, SpecialCharPipe, CustomCurrencyPipe, InArrayPipe, FilterPipe, PromoFilterPipe, pictureSrcsetPipe, ArraySortPipe,
        //directives
        ToggleShowHidePassDirective
    ],
    providers: [CurrencyPipe]
})

export class helpersModule {

}