export const SAVE_SELECTED_POLICY = 'SAVE_SELECTED_POLICY';
export const RESTORE_SELECTED_POLICY = 'RESTORE_SELECTED_POLICY';
export const CLEAR_SELECTED_POLICY = 'CLEAR_SELECTED_POLICY';

export function userSelectedPolicy(state: any = {}, action) {
  switch (action.type) {
    case SAVE_SELECTED_POLICY:
      return action.payload;
    case RESTORE_SELECTED_POLICY:
      return action.payload;
    case CLEAR_SELECTED_POLICY:
      return {};
    default:
      return state;
  }
}
